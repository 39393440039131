import { Link } from '@/components/link/link';
import styled from '@emotion/styled';
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import Arrow from './svgs/arrow.svg';

const MainDiv = styled.div`
  margin: 0;
  height: 790px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const Background = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;
const Title = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  position: absolute;
  font-size: 40px;
  color: #000000;
  margin-top: 128px;
  margin-bottom: 32px;
  z-index: 2;
  @media (max-width: 768px) {
    font-size: 32px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 20px;
  }
`;

const Content = styled(Grid)`
  margin: auto;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 90px 12.5% 0 12.5%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const BlobDiv = styled.div`
  min-height: 230px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 190px;
  }
`;
const Blobs1 = styled.img`
  height: 202px;
  width: 186px;
  @media (max-width: 768px) {
    max-width: 161px;
    height: auto;
  }
`;
const Blobs2 = styled.img`
  height: 223px;
  width: 232px;
  @media (max-width: 768px) {
    max-width: 154px;
    height: auto;
  }
`;
const Moment = styled(Grid)`
  width: 33.3%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 32px 32px 0 32px;
    min-height: 344px;
  }
`;

const MomentHeading = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 137.8%;
  text-align: center;
`;
const MomentText = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 18px;
  line-height: 160%;
  text-align: start;
  color: #000000;
  width: 100%;
  max-width: 300px;
  margin-bottom: 21.5px;
  margin-top: 36px;
  @media (max-width: 768px) {
    margin-bottom: 21.5px;
    margin-top: 5px;
    text-align: center;
  }
`;
const ArrowButton = styled(IconButton)`
  background-color: #f8d057;
  padding: 0;
  height: 35px;
  width: 35px;
  :hover {
    background: #f1caba;
  }
`;

const ArrowLabel = styled(Typography)`
  margin: 0 15px 0 0;
  font-family: var(--font-work-sans), sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  color: #231727;
  @media (max-width: 768px) {
    color: #723f6b;
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const Wave = styled.div`
  display: inline-block;
  height: 1px;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: visible;
  margin-top: 20px;
  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const FindMoments = () => {
  const isMobile = useMediaQuery('(max-width:960px)');
  return (
    <MainDiv>
      {!isMobile && <Background src="/vector201.png" />}
      <Title>Join us</Title>
      <Content container>
        <Moment>
          <BlobDiv>
            <Blobs1 src="/moments/blob3.png" alt={'experiences'} />
          </BlobDiv>
          <MomentHeading>Go on experiences</MomentHeading>
          <MomentText>
            Get to know new people, places, and parts of yourself with 1:1 meet-ups, groups, boxes
            and videos.
          </MomentText>
          <ButtonDiv>
            <ArrowLabel>Dive In</ArrowLabel>
            <Link href={'/discover/experiences'}>
              <ArrowButton size={'small'}>
                <Arrow />
              </ArrowButton>
            </Link>
          </ButtonDiv>
        </Moment>
        <Moment>
          <BlobDiv>
            <Blobs1 src="/moments/blob2.png" alt={'hosting'} />
          </BlobDiv>
          <MomentHeading>Host experiences</MomentHeading>
          <MomentText>
            We&apos;ll support you to turn your interests & passions into experiences, and make
            reciprocal connections.
          </MomentText>
          <ButtonDiv>
            <ArrowLabel>Dive In</ArrowLabel>
            <Link href={'/discover/hosting'}>
              <ArrowButton size={'small'}>
                <Arrow />
              </ArrowButton>
            </Link>
          </ButtonDiv>
        </Moment>
        <Moment>
          <BlobDiv>
            <Blobs2 src="/moments/coaching.png" alt={'coaching'} />
          </BlobDiv>
          <MomentHeading>Work with a Coach</MomentHeading>
          <MomentText>
            Identify your strengths, bust barriers, and make meaning with a Curiko Coach.
          </MomentText>
          <ButtonDiv>
            <ArrowLabel>Dive In</ArrowLabel>
            <Link href={'/discover/coaching'}>
              <ArrowButton>
                <Arrow />
              </ArrowButton>
            </Link>
          </ButtonDiv>
        </Moment>
      </Content>
      {isMobile && (
        <Wave>
          <svg
            width="100%"
            height="92"
            viewBox="0 0 1440 92"
            fill="none"
            preserveAspectRatio="xMidYMin slice"
            x="300"
            y="0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M615 47.7189C333 47.7189 158 135.855 0 63.4406V0.5H1450V17.2284C1450 17.2284 1522.98 37.6181 1206.5 76.7802C937 110.129 752.26 47.7189 615 47.7189Z"
              fill="#ffffff"
            />
          </svg>
        </Wave>
      )}
    </MainDiv>
  );
};

export default FindMoments;
