import styled from '@emotion/styled';
import { Grid, Link, Typography, useMediaQuery } from '@mui/material';
import ScrollArrow from './scroll.svg';
import Image from 'next/image';
import { YellowButton } from '@/components/button/button';
import VideoPlayer from '@/components/video-player/video-player';

const LandingDiv = styled(Grid)`
  height: auto;
  min-height: 115vh;
  margin: 0;
  position: relative;
  overflow: hidden;
  background: #231727;
  color: white;
  @media (max-width: 768px) {
    height: auto;
  }
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const RightDiv = styled(Grid)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  @media (max-width: 768px) {
    order: 2;
    padding: 0 20px;
    height: 65vh;
    min-height: 520px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const ShareMoments = styled(Grid)`
  height: 100%;
  margin-bottom: 120px;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 768px) {
    padding: 22px;
    margin-top: 98px;
    height: auto;
    margin-bottom: 0;
    margin-left: 0;
    align-items: flex-start;
  }
`;
const Video = styled.div`
  height: 70vh;
  width: 100%;
  z-index: 2;
  padding: 25vh 0 0 25%;
  @media (max-width: 768px) {
    margin-top: 110px;
    margin-bottom: auto;
    width: 100%;
    height: 30vh;
    padding: 0;
  }
`;

const BlobGroup = styled(Image)`
  position: absolute;
  z-index: 1;
  margin-top: 30px;
  height: 100vh;
  max-height: 1080px;
  width: 115%;
  @media (max-width: 768px) {
    left: -130px;
    height: auto;
    width: 100%;
  }
`;
const MobileBlob = styled(Image)`
  height: auto;
  width: 70%;
  max-width: 380px;
  position: absolute;
  z-index: 1;
  left: 0;
  margin-top: 10px;
`;
const ShareMomentsHeader = styled(Typography)`
  padding-top: 25vh;
  width: 60%;
  font-family: var(--font-cooper-light), sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 109.3%;
  color: #ffffff;
  text-align: start;
  z-index: 1;
  margin-bottom: 32px;
  width: 396px;
  @media (max-width: 768px) {
    width: 266px;
    padding-top: 0;
  }
`;
const ShareMomentsP = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 18px;
  line-height: 160%;
  color: #ffffff;
  text-align: start;
  margin-bottom: 32px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallBlobs = styled.img`
  top: 0;
  right: 0;
  height: auto;
  width: 12%;
  position: absolute;
  max-width: 100%;
  @media (max-width: 768px) {
    height: 200px;
    width: 125px;
    top: 0;
  }
`;
const ScrollDownArrow = styled.div`
  position: absolute;
  right: 32px;
  top: 60vh;
  z-index: 10;
  height: 144px;
  width: 24px;
`;
const Landing = (): React.ReactElement => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <>
      <LandingDiv container>
        {!isMobile && (
          <ScrollDownArrow>
            <ScrollArrow />
          </ScrollDownArrow>
        )}

        <RightDiv item md={6} xs={12} sm={12}>
          {isMobile ? (
            <MobileBlob src="/home/mobile/main-blob.png" width={524} height={907} alt="blobs" />
          ) : (
            <BlobGroup src="/group69392.png" width={2279} height={2647} alt="blobs" />
          )}
          <Video>
            <VideoPlayer
              thumbnail={'/home/home-video-still.png'}
              url={'https://www.youtube.com/watch?v=sVKQcRdEuHg'}
            />
          </Video>
        </RightDiv>
        <ShareMoments item md={6} xs={12} sm={12}>
          <ShareMomentsHeader>Let&apos;s share a moment together</ShareMomentsHeader>
          <ShareMomentsP>
            {`We’re a community of people with and without disabilities. Discover new things about yourself, others, and the world around you. Connect with your fellow humans through community-led experiences.`}
          </ShareMomentsP>
          <Link href="/explore">
            <YellowButton label={'Explore experiences'} />
          </Link>
        </ShareMoments>
        <SmallBlobs src="/group6492.png" width={534} height={983} alt={'small-blob'} />
      </LandingDiv>
    </>
  );
};

export default Landing;
