import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 287px;
  position: relative;
  background: #ffffff;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 3.55064px 3.55064px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    margin: 0;
    min-width: 249px;
  }
`;

const Avatar = styled.div`
  position: relative;
  top: -25px;
  margin-bottom: -20px;
  background-color: #e2e2e2;
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

export const CardSkeleton = (): React.ReactElement => {
  return (
    <Container>
      <Skeleton animation="wave" variant="rectangular" width="100%" height="45%" />
      <Box px={3}>
        <Avatar>
          <Skeleton animation="wave" variant="circular" width={50} height={50} />
        </Avatar>
        <Skeleton animation="wave" width="30%" />
        <Typography variant="h3">
          <Skeleton animation="wave" width="80%" />
        </Typography>
        <Skeleton animation="wave" width="70%" />
      </Box>
    </Container>
  );
};
