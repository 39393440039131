import { BorderedButton } from '@/components/button/button';
import { CardSkeleton } from '@/components/cards/card-skeleton/card-skeleton';
import ExperienceCard from '@/components/cards/experience-card/experience-card';
import { Link } from '@/components/link/link';
import { useNextGroupExperiencesQuery } from '@/generated/graphql';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BackWard from './svgs/backward.svg';
import ArrowFwd from './svgs/forward.svg';

const EventsMainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  margin-top: 130px;
  @media (max-width: 768px) {
    padding: 50px 20px 0 20px;
    height: auto;
    margin-top: 0;
  }
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const TitleDiv = styled.div`
  width: 100%;
  padding-left: 12.5%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const Slider = styled.div`
  position: relative;
  padding: 0 12.5%;
  overflow: visible;
  margin-top: 83px;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
`;
const Back = styled(IconButton)`
  position: absolute;
  left: 6%;
  :hover {
    background-color: transparent;
    svg,
    path {
      fill: #f8d057;
    }
  }
`;
const Forward = styled(IconButton)`
  position: absolute;
  right: 6%;
  :hover {
    background-color: transparent;
    svg,
    path {
      fill: #f8d057;
    }
  }
`;
const Title = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 112.3%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #231727;
  margin-top: 0;
  @media (max-width: 768px) {
    text-align: start;
    margin-left: 0;
    width: 100%;
    font-size: 32px;
    line-height: 112.3%;
    color: #231727;
    margin-bottom: 20px;
  }
`;
const CardWrapper = styled.div`
  height: 323px;
  width: 287px;
  @media (max-width: 768px) {
    height: 314px;
    width: 249px;
  }
`;
const SeeMoreButtonDiv = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1900 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const Events = () => {
  const now = useMemo(() => {
    return DateTime.now().startOf('hour').minus({ minute: 15 }).toISO();
  }, []);
  const { data, loading } = useNextGroupExperiencesQuery({ variables: { now } });
  const experiences = data?.experiences?.edges || [];
  const isMobile = useMediaQuery('(max-width:960px)');
  const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => {
    return (
      <Forward onClick={() => onClick?.()}>
        <ArrowFwd />
      </Forward>
    );
  };
  const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => {
    return (
      <Back onClick={() => onClick?.()}>
        <BackWard />
      </Back>
    );
  };

  if (!loading && !experiences.length) {
    return null;
  }

  return (
    <EventsMainDiv>
      <TitleDiv>
        <Title>{`What's up next?`}</Title>
      </TitleDiv>
      <Global
        styles={css`
          .react-multi-carousel-item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            a:hover {
              text-decoration: none;
            }
            @media (max-width: 768px) {
              justify-content: start;
            }
          }
          .react-multi-carousel-track {
            margin: 0 0 60px 6px !important;
            @media (max-width: 768px) {
              margin: 0 0 5px 6px !important;
            }
          }
          .react-multi-carousel-list {
            position: unset;
            padding-top: 10px;
          }
          .react-multi-carousel-dot {
            button {
              background-color: #767676;
              border: none;
            }
          }
          .react-multi-carousel-dot--active {
            button {
              background-color: #fdf3de;
            }
          }
        `}
      />
      <Slider>
        <Carousel
          additionalTransfrom={0}
          centerMode={false}
          swipeable
          draggable
          showDots={!isMobile}
          arrows
          responsive={responsive}
          ssr
          partialVisible={isMobile}
          keyBoardControl={true}
          transitionDuration={500}
          slidesToSlide={isMobile ? 1 : 3}
          containerClass="container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          deviceType={isMobile ? 'mobile' : 'desktop'}
          sliderClass=""
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          {loading
            ? [0, 1, 2, 3].map(key => (
                <CardWrapper key={key}>
                  <CardSkeleton />
                </CardWrapper>
              ))
            : experiences.map(experience => (
                <CardWrapper key={experience.node.id}>
                  <ExperienceCard experience={experience.node} />
                </CardWrapper>
              ))}
        </Carousel>
      </Slider>
      <SeeMoreButtonDiv>
        <Link href={'/explore/?time=ThisWeek'}>
          <BorderedButton primary={false} label="See more upcoming" />
        </Link>
      </SeeMoreButtonDiv>
    </EventsMainDiv>
  );
};

export default Events;
