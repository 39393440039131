import React from 'react';
import styled from '@emotion/styled';
import { CreamButton } from '@/components/button/button';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Link } from '@/components/link/link';

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -50px;
  height: calc(47.7vh + 50px);
  background: #231727;
  padding: 0 12.5%;
  @media (max-width: 760px) {
    margin-top: 0;
    padding: 20px;
    height: 70vh;
  }
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const Left = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 760px) {
    order: 2;
    align-items: flex-start;
    margin-top: 32px;
  }
`;
const Right = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const Wrapper = styled(Grid)`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-content: start;
  padding: 10% 0 0 0;
`;
const Heading = styled(Typography)`
  margin-top: 0;
  font-family: var(--font-cooper-light), sans-serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 112.3%;
  color: #ffffff;
  text-align: end;
  @media (max-width: 760px) {
    text-align: start;
  }
`;
const MissionStatementDiv = styled.div`
  margin: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 760px) {
    align-items: flex-start;
  }
`;

const MissionStatement = styled(Typography)`
  font-family: var(--font-work-sans), sans-serif;
  font-size: 18px;
  line-height: 159.3%;
  color: #ffffff;
  text-align: start;
  margin-bottom: 32px;
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const OurMission = () => {
  const isMobile = useMediaQuery('(max-width:960px)');
  return (
    <MainDiv>
      <Wrapper container>
        <Left item md={6} sm={12} xs={12}>
          <MissionStatementDiv>
            <MissionStatement>
              {`Curiko sparks more moments of connection between people whose bodies and brains work differently, creating the conditions for equal relationships and good lives.`}
            </MissionStatement>
            <Link href={'/about/origins'}>
              <CreamButton primary={false} label={isMobile ? 'Our origins' : 'See our origins'} />
            </Link>
          </MissionStatementDiv>
        </Left>
        <Right item md={6} sm={12} xs={12}>
          <Heading>Our mission</Heading>
        </Right>
      </Wrapper>
    </MainDiv>
  );
};

export default OurMission;
