import { Link } from '@/components/link/link';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import Image from 'next/image';

import advocateGroup from '@/../public/tasks/advocate/group.png';
import connectGroup from '@/../public/tasks/connect/group.png';
import exploreGroup from '@/../public/tasks/explore/group.png';
import knitting from '@/../public/tasks/make/knitting1.png';
import moveGroup from '@/../public/tasks/move/group.png';
import tasteGroup from '@/../public/tasks/taste/group.png';

const MainDiv = styled.div`
  height: auto;
  margin-top: -1px;
  & a {
    :hover {
      text-decoration: none;
    }
  }
`;
const ActivityDiv = styled.div`
  height: auto;
  margin: 0;
  background: #231727;
  @media (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;
const Header = styled(Typography)`
  font-family: var(--font-cooper-light), sans-serif;
  line-height: 112.3%;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 78px;
  @media (max-width: 768px) {
    padding: 0 30px;
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 112.3%;
  }
`;
const TaskGroup = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 12.5%;
  @media (max-width: 768px) {
    padding: 0 15px 80px 15px;
  }
`;
const Underline = styled.div`
  height: auto;
  width: auto;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`;
const Task = styled(Grid)`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;
const TaskContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  :hover {
    ${Underline} {
      visibility: visible;
      @media (max-width: 768px) {
        visibility: hidden;
      }
    }
  }
`;
const UnderlineTick = styled.img`
  height: 14px;
  width: 74px;
  object-fit: cover;
  margin: auto;
`;
const TaskCircle = styled.div`
  height: 145px;
  width: 145px;
  background-color: #4c3b51;
  border-radius: 100%;
  :hover {
    background-color: #f8d057;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    height: 88px;
    width: 88px;
  }
`;

const TaskImage1 = styled(Image)`
  height: 131px;
  width: 91px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    height: 80px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const TaskImage2 = styled(Image)`
  margin: auto auto 17px 31px;
  height: 139px;
  width: 108px;
  @media (max-width: 768px) {
    margin: -5px 5px 9px 17px;
    height: 85px;
    width: 65px;
  }
`;
const TaskImage3 = styled(Image)`
  margin: 10px 0 auto auto;
  height: 141px;
  width: 99px;
  @media (max-width: 768px) {
    margin: 7px 0 auto auto;
    height: 85px;
    width: 60px;
  }
`;
const TaskImage4 = styled(Image)`
  margin: auto auto 20px auto;
  height: 130px;
  width: 103px;
  @media (max-width: 768px) {
    margin: auto auto 15px auto;
    width: 62.63px;
    height: 79.05px;
  }
`;
const TaskImage5 = styled(Image)`
  margin: 17px 0 auto auto;
  height: 97px;
  width: 111px;
  @media (max-width: 768px) {
    margin: 10px 0 20px auto;
    height: 58.6px;
    width: 67.44px;
  }
`;
const TaskImage6 = styled(Image)`
  margin-left: -6px;
  margin-top: 6px;
  height: 94px;
  width: 129px;
  @media (max-width: 768px) {
    margin: 4px auto 28px -4px;
    height: 57.16px;
    width: 78.44px;
  }
`;
const TaskName = styled(Typography)`
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
`;
const Wave = styled.svg`
  display: block;
  margin-top: -1px;
`;

const Activity = (): React.ReactElement => {
  const UnderTick = () => {
    return (
      <Underline>
        <UnderlineTick src="/tick.png" />
      </Underline>
    );
  };
  return (
    <MainDiv>
      <ActivityDiv>
        <Header>What would you like to try?</Header>
        <TaskGroup container>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Taste'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage1 src={tasteGroup} alt={'taste'} />
                </TaskCircle>
                <TaskName>Taste</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Make'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage2 src={knitting} alt={'make'} />
                </TaskCircle>
                <TaskName>Make</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Move'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage3 src={moveGroup} alt={'move'} />
                </TaskCircle>
                <TaskName>Move</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Connect'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage4 src={connectGroup} alt={'connect'} />
                </TaskCircle>
                <TaskName>Connect</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Explore'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage5 src={exploreGroup} alt={'explore'} />
                </TaskCircle>
                <TaskName>Explore</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
          <Task item md={2} xs={4} sm={4}>
            <Link href={'/explore/?category=Advocate'}>
              <TaskContent>
                <TaskCircle>
                  <TaskImage6 src={advocateGroup} alt={'advocate'} />
                </TaskCircle>
                <TaskName>Advocate</TaskName>
                <UnderTick />
              </TaskContent>
            </Link>
          </Task>
        </TaskGroup>
      </ActivityDiv>
      <Wave xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 120">
        <path
          fill="#231727"
          fillOpacity="1"
          d="M0,96L80,101.3C160,107,320,117,480,106.7C640,96,800,64,960,58.7C1120,53,1280,75,1360,85.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </Wave>
    </MainDiv>
  );
};

export default Activity;
