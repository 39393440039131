import styled from '@emotion/styled';
import Image from 'next/image';
import { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';

const MainDiv = styled.div`
  position: relative;
  padding-top: 60.25%;
  z-index: 10;
`;
const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 20px;
`;
const PlayButton = styled.img`
  height: 100px;
  width: 100px;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
  }
  @media (min-width: 1440px) {
    height: 114px;
    width: 114px;
  }
`;

const PlayIcon = () => {
  return <PlayButton src={'/playButtonHq.png'} />;
};

export type InlineVideoPlayerProps = {
  url: string;
  thumbnail?: string;
};

const InlineVideoPlayer = ({ url, thumbnail }: InlineVideoPlayerProps) => {
  const [play, setPlay] = useState<boolean>(false);
  const handlePlay = useCallback(() => {
    setPlay(true);
  }, []);

  return (
    <MainDiv>
      <Player
        light={thumbnail || true}
        playIcon={<PlayIcon />}
        url={url}
        width="100%"
        height="100%"
        controls={true}
        playing={play}
        onClickPreview={handlePlay}
        config={{
          youtube: {
            playerVars: {
              cc_load_policy: 1,
              showinfo: 0,
              modestbranding: 1,
              rel: 0,
            },
          },
        }}
      />
    </MainDiv>
  );
};

const ImageButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 314px;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transform: translateZ(0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: rgb(35, 23, 39);

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 275px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 250px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 200px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0px 3px rgba(255, 207, 54, 0.7);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    background-color: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    transition: opacity ease-out 0.2s;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    background-image: url(/playButtonHq.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    transform: scale(1);
    transition: transform ease-out 0.2s;
  }

  &:hover {
    &::before {
      opacity: 0.4;
    }

    &::after {
      transform: scale(1.3);
    }
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
`;

type FullScreenVideoPlayerProps = InlineVideoPlayerProps & {
  options?: string;
  id?: string;
};

export const FullScreenVideoPlayer = ({
  url,
  thumbnail,
  id,
}: FullScreenVideoPlayerProps): React.ReactElement => {
  return (
    <ImageButton data-fancybox={id || url} data-src={url}>
      {thumbnail && <StyledImage src={thumbnail} alt="Video Thumbnail" fill />}
    </ImageButton>
  );
};

type VideoPlayerProps = InlineVideoPlayerProps &
  FullScreenVideoPlayerProps & {
    inline?: boolean;
  };

export const VideoPlayer = ({ inline, ...other }: VideoPlayerProps): React.ReactElement => {
  return <>{inline ? <InlineVideoPlayer {...other} /> : <FullScreenVideoPlayer {...other} />}</>;
};

export default VideoPlayer;
